import { computed } from "vue";
import { useCustomerStore } from "../stores/customer";
import { useZendeskStore } from "../stores/zendesk";

export function useHpPartnerLogic() {

  const customerStore = useCustomerStore()
  const zendeskStore = useZendeskStore()

  const hpDNIS = '8082073795'
  const hpTicketToAddress = 'hpsupport@iolo.com'
  const hpCustomerNotice = 'HP CUSTOMER'
  const hpSkuLabel = ' (HP)'

  const hpZendeskMacros = {
    hp_activate: 'Installation and Activation',
    hp_adding_protection_to_other_pcs: 'Adding protection to other PCs',
    hp_receiving_an_error_message: 'Receiving an error message',
    hp_changing_scheduled_maintenance: 'Changing scheduled maintenance',
    hp_system_performance_slow_after_running_system: 'System performance slow after running system',
    hp_others: 'Others'
  }

  const isHpSubscription = (sub) => sub.sku?.endsWith('_HP') || sub.sku?.startsWith('HP_')

  const isHpCustomer = computed(() => {
    if (customerStore.getDNIS === hpDNIS) return true
    let ticketHp = false
    const ticket = zendeskStore.getTicket
    if (ticket && ticket.via && ticket.via.channel == 'email' && ticket.via.source.to) {
      if (ticket.via.source.to.address) ticketHp = ticket.via.source.to.address == hpTicketToAddress
    }
    if (ticketHp) return true
    return customerStore.getSubscriptions?.some(s => isHpSubscription(s) && s.status.startsWith('Active'))
  })

  return { isHpCustomer, hpZendeskMacros, hpDNIS, hpTicketToAddress, isHpSubscription, hpCustomerNotice, hpSkuLabel }
}