import {defineStore} from 'pinia'
import {useCustomerStore} from './customer'
import {api} from '@/plugins/axios'
import event from '@/plugins/events'
import { TRIGGER_CONFIRM_DIALOG } from '../plugins/events'

const storage = window.sessionStorage

export const useZendeskStore = defineStore('zendesk', {
  state: () => ({
    ticket: storage.getItem('ZendeskTicket') ? JSON.parse(storage.getItem('ZendeskTicket')) : null,
    createTicket: false,
    techTicket: JSON.parse(storage.getItem('TechTicket') || null),
  }),
  getters: {
    getTicket: ({ticket}) => ticket,
    getTicketId: ({ticket}) => ticket?.id,
    getRequesterId: ({ticket}) => ticket?.requester_id,
    getTechTicket: ({ techTicket }) => techTicket,
    getTechTicketId: ({ techTicket }) => techTicket?.id,
    getTicketUrl({ticket}) {
      return (ticket_id) => {
        if (!ticket_id && !ticket?.id) return null
        return `https://${import.meta.env.VITE_ZENDESK_SUB_DOMAIN}.zendesk.com/agent/tickets/${ticket_id || ticket?.id}`
      }
    },
    getCustomerUrl({ticket}){
      if (!ticket?.requester_id) return null
      return `https://${import.meta.env.VITE_ZENDESK_SUB_DOMAIN}.zendesk.com/agent/users/${ticket.requester_id}/requested_tickets`
    },
  },
  actions: {
    setCreateTicket(val) {
      this.createTicket = val
    },
    setTicket(ticket) {
      this.ticket = Object.assign({}, ticket)
      storage.setItem('ZendeskTicket', JSON.stringify(this.ticket))
      const customerStore = useCustomerStore()
      const screenPopId = customerStore.getScreenPopId
      if (screenPopId) api.post(`/customers/screen_pop`, {id: screenPopId, ticket_id: ticket.id})
    },
    setTechTicket(data) {
      if (data) {
        this.techTicket = { id: this.techTicket?.id, ...data };
        storage.setItem('TechTicket', JSON.stringify(this.techTicket));
      } else {
        this.techTicket = null;
        storage.removeItem('TechTicket');
      }
    },
    setTechTicketId(id) {
      this.techTicket = Object.assign(this.techTicket, { id });
      storage.setItem('TechTicket', JSON.stringify(this.techTicket));
    },
    async handleTicket(id) {
      const ticket = await api.get(`/zendesk/tickets/${id}`)
      if (ticket.status == 'closed') throw `Ticket ID ${id} is Closed. Please choose a different ticket.`
      this.setCreateTicket(false)
      this.setTicket(ticket)
    },
    logout() {
      this.ticket = null
      this.createTicket = false
      this.techTicket = null;
      storage.removeItem('ZendeskTicket')
      this.setTechTicket();
    },
    validateTicket() {
      try {
        if (!this.getTicketId) {
          event.emit('openSetTicketId')
          throw `A Zendesk ticket is required for this action`
        }
        const customerStore = useCustomerStore()
        if (customerStore.getZendeskId && customerStore.getZendeskId != this.getRequesterId) {
          if (!confirm(`Ticket ID ${this.getTicketId} is not assigned to the selected customer. Are you sure you want to take action using this ticket?`)) {
            event.emit('openSetTicketId')
            throw `Select a new ticket to use`
          }
          return true
          // CANNOT USE EVENT BECAUSE NEED TO RETURN TRUE ON YES
          // event.emit(TRIGGER_CONFIRM_DIALOG, {
          //   message: `Ticket ID ${this.getTicketId} is not assigned to the selected customer. Are you sure you want to take action using this ticket?`,
          //   onNo: () => {
          //     event.emit(TRIGGER_CONFIRM_DIALOG)
          //     event.emit('openSetTicketId')
          //     throw `Select a new ticket to use`
          //   },
          //   onYes: () => {
          //     event.emit(TRIGGER_CONFIRM_DIALOG)
          //     return true
          //   }
          // })
        } else {
          return true
        }
      } catch (e) {
        event.error(e)
        return false
      }
    }
  }
})